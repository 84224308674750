import React, { useState, createContext, useContext } from 'react';
import { agents } from '../config/agents';

// Create a context for the selected agent
export const AgentContext = createContext();

export const useAgent = () => {
  const context = useContext(AgentContext);
  if (!context) {
    throw new Error('useAgent must be used within an AgentProvider');
  }
  return context;
};

export const AgentProvider = ({ children }) => {
  const [selectedAgent, setSelectedAgent] = useState(agents[0]);

  return (
    <AgentContext.Provider value={{ selectedAgent, setSelectedAgent }}>
      {children}
    </AgentContext.Provider>
  );
};

const AgentSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedAgent, setSelectedAgent } = useAgent();

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 px-4 py-2 rounded-lg bg-gray-800/50 backdrop-blur-sm border border-gray-700/30 hover:border-blue-500/30 transition-all duration-300"
      >
        <img
          src={selectedAgent.logo}
          alt={selectedAgent.name}
          className="w-5 h-5 object-contain"
        />
        <span className="text-gray-300 text-sm">{selectedAgent.name}</span>
        <svg
          className={`w-4 h-4 text-gray-400 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-lg bg-gray-800/90 backdrop-blur-sm border border-gray-700/30 shadow-lg py-1 z-50">
          {agents.map((agent) => (
            <button
              key={agent.name}
              onClick={() => {
                setSelectedAgent(agent);
                setIsOpen(false);
              }}
              className={`w-full flex items-center px-4 py-2 text-sm ${
                selectedAgent.name === agent.name
                  ? 'text-blue-400 bg-gray-700/50'
                  : 'text-gray-300 hover:bg-gray-700/30'
              } transition-colors duration-200`}
            >
              <img src={agent.logo} alt={agent.name} className="w-5 h-5 mr-3 object-contain" />
              {agent.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default AgentSelector;
