import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container mx-auto px-4 py-16 text-gray-300">
      <h1 className="text-4xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-violet-500">
        Terms of Service
      </h1>
      
      <div className="space-y-8 max-w-3xl mx-auto">
        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">1. Acceptance of Terms</h2>
          <p className="text-gray-400">
            By accessing and using this website, you accept and agree to be bound by the terms and provisions of this agreement.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">2. Use License</h2>
          <p className="text-gray-400">
            This tool is intended for legitimate use cases only. Users are prohibited from:
          </p>
          <ul className="list-disc pl-6 mt-2 text-gray-400 space-y-1">
            <li>Using the service for any unlawful purpose</li>
            <li>Attempting to bypass or manipulate the service</li>
            <li>Accessing the service through automated means</li>
            <li>Redistributing or reselling the service</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">3. Disclaimer</h2>
          <p className="text-gray-400">
            The service is provided "as is" without any warranties, expressed or implied. We do not warrant that the service will be uninterrupted or error-free.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">4. Limitations</h2>
          <p className="text-gray-400">
            In no event shall we be liable for any damages arising out of the use or inability to use the service.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">5. Revisions</h2>
          <p className="text-gray-400">
            We reserve the right to revise these terms at any time without notice. By using this website, you agree to be bound by the current version of these Terms of Service.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
