import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-16 text-gray-300">
      <h1 className="text-4xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-violet-500">
        Privacy Policy
      </h1>
      
      <div className="space-y-8 max-w-3xl mx-auto">
        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">1. Information We Collect</h2>
          <p className="text-gray-400">
            We collect information that you provide directly to us, including:
          </p>
          <ul className="list-disc pl-6 mt-2 text-gray-400 space-y-1">
            <li>Account information</li>
            <li>Usage data</li>
            <li>Technical information about your device and internet connection</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">2. How We Use Your Information</h2>
          <p className="text-gray-400">We use the collected information to:</p>
          <ul className="list-disc pl-6 mt-2 text-gray-400 space-y-1">
            <li>Provide and maintain our service</li>
            <li>Improve and personalize your experience</li>
            <li>Communicate with you about updates and changes</li>
            <li>Detect and prevent fraud or abuse</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">3. Data Storage and Security</h2>
          <p className="text-gray-400">
            We implement appropriate technical and organizational measures to protect your personal information. However, no method of transmission over the Internet is 100% secure.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">4. Third-Party Services</h2>
          <p className="text-gray-400">
            Our service may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites. Please note that any data you upload or provide to our service will be shared with our third-party providers to facilitate our services, including but not limited to product search.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">5. Your Rights</h2>
          <p className="text-gray-400">You have the right to:</p>
          <ul className="list-disc pl-6 mt-2 text-gray-400 space-y-1">
            <li>Access your personal data</li>
            <li>Request correction of your personal data</li>
            <li>Request deletion of your personal data</li>
            <li>Object to processing of your personal data</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">6. Changes to This Policy</h2>
          <p className="text-gray-400">
            We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
