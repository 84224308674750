import React, { useState } from "react";

const HeroSection = ({ imgSrc, title, source }) => {
  const [isLoading, setIsLoading] = useState(true);
  
  const truncateText = (text, maxLength = 50) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  return (
    <div className="relative w-full h-full bg-black overflow-hidden">
      {/* Blurred background image */}
      <div className="absolute inset-0">
        <img
          alt="Background"
          className={`w-full h-full object-cover scale-110 blur-xl transition-opacity duration-300 ${isLoading ? 'opacity-0' : 'opacity-70'}`}
          src={imgSrc}
          onLoad={() => setIsLoading(false)}
        />
      </div>
      
      {/* Main image container */}
      <div className="absolute inset-0">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900">
            <div className="w-12 h-12 border-4 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
          </div>
        )}
        <img
          alt="Product Hero"
          className={`w-full h-full object-contain transition-opacity duration-300 ${isLoading ? 'opacity-0' : 'opacity-100'}`}
          src={imgSrc}
          onLoad={() => setIsLoading(false)}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black/0 via-black/25 to-black/90" />
      </div>
      <div className="relative h-full flex flex-col justify-end pb-12 p-8">
        <div className="max-w-3xl">
          <h1 className="text-2xl font-bold text-white mb-2">{truncateText(title, 50)}</h1>
          <p className="text-gray-300 text-lg">From {source}</p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
