export const agents = [
  {
    name: 'Sugargoo',
    logo: '/img/sugargo.png',
    orderUrl: 'https://www.sugargoo.com/#/home/productDetail?productLink='
  },
  {
    name: 'Superbuy',
    logo: '/img/suprbuy.png',
    orderUrl: 'https://www.superbuy.com/en/page/buy/?url='
  },
  {
    name: 'AllChinaBuy',
    logo: '/img/allchinabuy.png',
    orderUrl: 'https://www.allchinabuy.com/en/page/buy/?_search=url&url='
  },
  {
    name: 'Wegobuy',
    logo: '/img/wgbuy.png',
    orderUrl: 'https://www.wegobuy.com/en/page/buy?url='
  },
  {
    name: 'Hoobuy',
    logo: '/img/hoobuy.png',
    orderUrl: 'https://hoobuy.com/fill-buy?url='
  },
];
