import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import FetchAndDisplayProducts from "./FetchAndDisplayProducts";
import SearchBar from "./components/SearchBar";
import ProductShowcase from "./components/ProductShowcase";
import SupportedSites from "./components/SupportedSites";
import AgentSelector, { AgentProvider } from "./components/AgentSelector";
import TermsOfService from "./components/legal/TermsOfService";
import PrivacyPolicy from "./components/legal/PrivacyPolicy";
import DMCANotice from "./components/legal/DMCANotice";
import { WorldMap } from "./components/ui/WorldMap";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-400 py-4 sm:py-8 border-t border-gray-800">
      <div className="container mx-auto px-4 max-w-screen-xl">
        {/*Disclaimer*/}
        <p className="text-center text-gray-400 text-xs sm:text-sm mb-4 sm:mb-6 px-2 sm:px-4">
          Disclaimer: This website is intended to assist users browsing Chinese
          marketplaces, compare products, and convert currencies. We do not
          endorse, verify, or guarantee the legitimacy, authenticity, or
          legality of any products, sellers, or linked websites. Users are
          advised to proceed at their own discretion. We explicitly prohibit any
          usage of our site for any unintended purposes. It is the user's
          responsibility to thoroughly verify products and sellers before
          engaging in any transactions. The information provided is for
          reference only, and we assume no liability for any actions taken based
          on the content of this website. <br />
          By using this website, you acknowledge and agree to our terms and
          conditions, privacy policy, and DMCA notice.
          <br />
          Cnbuy.io is not affiliated with any of the sites listed on this
          platform.
        </p>

        <div className="flex flex-wrap justify-center gap-4 sm:gap-8">
          <Link to="/terms" className="hover:text-blue-500 transition-colors">
            Terms of Service
          </Link>
          <Link to="/privacy" className="hover:text-blue-500 transition-colors">
            Privacy Policy
          </Link>
          <Link to="/dmca" className="hover:text-blue-500 transition-colors">
            DMCA Notice
          </Link>
        </div>
        <div className="text-center mt-4 text-xs sm:text-sm">
          &copy; {new Date().getFullYear()} cnbuy.io All rights reserved.
        </div>
      </div>
    </footer>
  );
};

const HomePage = () => {
  return (
    <div className="min-h-screen bg-gray-900">
      {/* Logo */}
      <div className="absolute top-4 left-4 z-50">
        <Link to="/">
          <img
            src="/img/cnbuy.io.png"
            alt="CNBuy Logo"
            className="h-8 w-auto m-4"
          />
        </Link>
      </div>

      {/* Agent Selector */}
      <div className="absolute top-4 right-4 z-50">
        <AgentSelector />
      </div>

      {/* Hero Section */}
      <div className="relative min-h-[80vh] flex flex-col items-center justify-center p-2 sm:p-4">
        {/* Background Elements */}
        <div className="absolute inset-0 pointer-events-none">
          {/* Grid Background */}
          <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f46e510_1px,transparent_1px),linear-gradient(to_bottom,#4f46e510_1px,transparent_1px)] bg-[size:24px_24px]" />

          {/* Radial Gradient */}
          <div className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-500/10 to-transparent" />

          {/* Glowing Orb */}
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-blue-500/30 rounded-full blur-3xl" />

          {/* WorldMap */}
          <WorldMap
            dots={[
              {
                start: {
                  lat: 64.2008,
                  lng: -149.4937,
                },
                end: {
                  lat: 34.0522,
                  lng: -118.2437,
                },
              },
              {
                start: { lat: 64.2008, lng: -149.4937 }, // Alaska (Fairbanks)
                end: { lat: -15.7975, lng: -47.8919 }, // Brazil (Brasília)
              },
              {
                start: { lat: -15.7975, lng: -47.8919 }, // Brazil (Brasília)
                end: { lat: 38.7223, lng: -9.1393 }, // Lisbon
              },
              {
                start: { lat: 51.5074, lng: -0.1278 }, // London
                end: { lat: 28.6139, lng: 77.209 }, // New Delhi
              },
              {
                start: { lat: 28.6139, lng: 77.209 }, // New Delhi
                end: { lat: 43.1332, lng: 131.9113 }, // Vladivostok
              },
              {
                start: { lat: 28.6139, lng: 77.209 }, // New Delhi
                end: { lat: -1.2921, lng: 36.8219 }, // Nairobi
              },
            ]}
          />

          {/* Bottom Fade */}
          <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-gray-900 to-transparent" />
        </div>

        {/* Content Overlay */}
        <div className="relative z-20 flex flex-col items-center gap-8 md:gap-10 max-w-5xl mx-auto pointer-events-auto">
          <div className="inline-block">
            <span className="px-4 py-2 rounded-full text-sm font-medium bg-blue-500/10 text-blue-400 border border-blue-500/20">
              NEW: V2 | Added More Agents
            </span>
          </div>
          <div className="space-y-2 sm:space-y-4 text-center">
            <h1 className="text-4xl sm:text-5xl md:text-7xl font-bold opacity-0 animate-fade-in-up" style={{ animationDelay: '0.2s' }}>
              <span className="bg-clip-text text-white whitespace-nowrap inline-block">
                🔍 Find Suppliers ✈️
              </span>
              <br />
              {/*<span className="text-4xl sm:text-5xl md:text-6xl opacity-0 animate-fade-in-up" style={{ animationDelay: '0.4s' }}>
                👕 <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-orange-400">From any Product URL</span> 🔗
              </span>
              <span className="text-4xl sm:text-5xl md:text-6xl opacity-0 animate-fade-in-up" style={{ animationDelay: '0.4s' }}>
                👕 <span className="bg-clip-text text-transparent bg-gradient-to-r from-neutral-200 to-[#4f46e5]">From any Product URL</span> 🔗
              </span>*/}
              <span className="text-3xl sm:text-4xl md:text-6xl opacity-0 animate-fade-in-up" style={{ animationDelay: '0.4s' }}>
                <span className="hidden sm:inline">👕 </span>
                <span className="bg-clip-text text-transparent bg-gradient-to-b from-white to-[#8681e2]">From any Product URL</span>
                <span className="hidden sm:inline"> 🔗</span>
              </span>
            </h1>
            
            <p className="text-lg sm:text-xl md:text-2xl text-gray-400 max-w-2xl mx-auto opacity-0 animate-fade-in px-2 sm:px-4" style={{ animationDelay: '0.6s' }}>
              Find product alternatives from Chinese marketplaces, just by
              pasting the product URL of the product you want.
            </p>
          </div>
          <div className="w-full max-w-4xl mx-auto opacity-0 animate-fade-in" style={{ animationDelay: '0.8s' }}>
            <SearchBar />
          </div>
          <div className="opacity-0 animate-fade-in" style={{ animationDelay: '1s' }}>
            <SupportedSites />
          </div>
        </div>
      </div>

      {/* Product Showcase Section */}
      <div className="relative -mt-32 pt-32 pb-16 z-10">
        <div className="relative opacity-0 animate-fade-in-up" style={{ animationDelay: '1.2s' }}>
          <ProductShowcase />
        </div>
      </div>
      {/* How It Works Section */}
      <div className="relative z-10">
        <div className="max-w-7xl mx-auto px-4 py-16">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-white mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-violet-400 opacity-0 animate-fade-in-up" style={{ animationDelay: '1.4s' }}>
              How It Works
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 mt-12">
              <div className="p-4 md:p-8 rounded-2xl bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 transform transition-all duration-300 hover:scale-105 hover:bg-gray-800/70 opacity-0 animate-fade-in-up" style={{ animationDelay: '1.6s' }}>
                <div className="text-blue-400 text-xl md:text-2xl font-bold mb-2">
                  1
                </div>
                <h3 className="text-white text-lg md:text-xl font-semibold mb-2">
                  Copy Product URL
                </h3>
                <p className="text-gray-400 text-sm md:text-base">
                  Find a product you like and copy its URL
                </p>
                <div className="mt-4 rounded-lg overflow-hidden border-2 border-gray-700 aspect-[2/1]">
                  <img
                    src="/screenshots/copy-url.svg"
                    alt="Copy URL example"
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>
              <div className="p-4 md:p-8 rounded-2xl bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 transform transition-all duration-300 hover:scale-105 hover:bg-gray-800/70 opacity-0 animate-fade-in-up" style={{ animationDelay: '1.8s' }}>
                <div className="text-blue-400 text-xl md:text-2xl font-bold mb-2">
                  2
                </div>
                <h3 className="text-white text-lg md:text-xl font-semibold mb-2">
                  Paste URL
                </h3>
                <p className="text-gray-400 text-sm md:text-base">
                  Paste the product URL in our search bar
                </p>
                <div className="mt-4 rounded-lg overflow-hidden border-2 border-gray-700 aspect-[2/1]">
                  <img
                    src="/screenshots/paste-url.svg"
                    alt="Paste URL example"
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>
              <div className="p-4 md:p-8 rounded-2xl bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 transform transition-all duration-300 hover:scale-105 hover:bg-gray-800/70 opacity-0 animate-fade-in-up" style={{ animationDelay: '2s' }}>
                <div className="text-blue-400 text-xl md:text-2xl font-bold mb-2">
                  3
                </div>
                <h3 className="text-white text-lg md:text-xl font-semibold mb-2">
                  AI Search
                </h3>
                <p className="text-gray-400 text-sm md:text-base">
                  Our tools finds similar products across chinese platforms
                </p>
                <div className="mt-4 rounded-lg overflow-hidden border-2 border-gray-700 aspect-[2/1]">
                  <img
                    src="/screenshots/ai-search.svg"
                    alt="AI Search example"
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

function App() {
  return (
    <Router>
      <AgentProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/dmca" element={<DMCANotice />} />
          <Route path="/*" element={<FetchAndDisplayProducts />} />
        </Routes>
      </AgentProvider>
    </Router>
  );
}

export default App;
