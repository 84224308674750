import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SearchBar = () => {
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const validateUrl = (url) => {
    try {
      const formattedUrl = formatUrl(url);
      new URL(formattedUrl);

      // Check for common URL patterns that might indicate invalid or malicious URLs
      const suspiciousPatterns = ['localhost', '127.0.0.1', '.local', 'file:/'];
      if (suspiciousPatterns.some(pattern => formattedUrl.includes(pattern))) {
        throw new Error('Local or invalid URLs are not supported.');
      }

      // Check for valid hostname (must have at least one dot and valid TLD)
      const hostname = new URL(formattedUrl).hostname;
      if (!hostname.includes('.') || hostname.endsWith('.') || hostname.startsWith('.')) {
        throw new Error('Invalid domain name format.');
      }

      return { isValid: true, formattedUrl };
    } catch (err) {
      return { 
        isValid: false, 
        error: err.message || 'Invalid URL provided. Please check the URL and try again.'
      };
    }
  };

  const formatUrl = (url) => {
    let formattedUrl = url.trim();
    
    // Remove any existing protocol
    formattedUrl = formattedUrl.replace(/^(https?:\/\/)/, '');
    
    // Add https:// protocol
    formattedUrl = 'https://' + formattedUrl;
    
    return formattedUrl;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (url) {
      const validation = validateUrl(url);
      if (validation.isValid) {
        setError('');
        navigate(`/${encodeURIComponent(validation.formattedUrl)}`);
        setUrl('');
      } else {
        setError(validation.error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full mx-auto">
      <div className="relative p-[1px] rounded-lg bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 animate-border-animate bg-gradient-animate">
        <div className="relative flex rounded-lg overflow-hidden bg-gray-900">
          <div className="bg-gray-800 text-gray-300 px-3 py-3 font-mono flex items-center">
            cnbuy.io/
            <div className="ml-3 h-[60%] border-r-2 border-gray-700/70"></div>
          </div>
          <input
            type="text"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              setError(''); // Clear error when user starts typing
            }}
            placeholder="www.example.com/product"
            className={`w-full pl-3 pr-4 py-3 bg-gray-800 text-white focus:outline-none placeholder-gray-500 ${
              error ? 'border-red-500' : ''
            }`}
          />
          <button
            type="submit"
            className="px-6 py-3 bg-gray-800/50 backdrop-blur-sm text-white border border-gray-700 hover:bg-gray-700/50 hover:border-gray-600 hover:scale-105 transition-all duration-300 flex items-center justify-center rounded-r-lg shadow-lg"
          >
            <span className="mr-2">Search</span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-5 w-5" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" 
              />
            </svg>
          </button>
        </div>
      </div>
      {error && (
        <div className="mt-2 text-red-500 text-sm">
          {error}
        </div>
      )}
      <style jsx>{`
        @keyframes border-animate {
          0%, 100% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
        }
      `}</style>
    </form>
  );
};

export default SearchBar;
