import React from 'react';

const DMCANotice = () => {
  return (
    <div className="container mx-auto px-4 py-16 text-gray-300">
      <h1 className="text-4xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-violet-500">
        DMCA Notice & Takedown Policy
      </h1>
      
      <div className="space-y-8 max-w-3xl mx-auto">
        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">1. Compliance with DMCA</h2>
          <p className="text-gray-400">
            We respect the intellectual property rights of others and expect our users to do the same. We will respond to notices of alleged copyright infringement that comply with applicable law and are properly provided to us.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">2. DMCA Notice Requirements</h2>
          <p className="text-gray-400">
            If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement, please provide our copyright agent with the following information:
          </p>
          <ul className="list-disc pl-6 mt-2 text-gray-400 space-y-1">
            <li>A physical or electronic signature of the copyright owner</li>
            <li>Identification of the copyrighted work claimed to have been infringed</li>
            <li>Identification of the material that is claimed to be infringing</li>
            <li>Contact information, including your address, telephone number, and email</li>
            <li>A statement that you have a good faith belief that use of the material is not authorized</li>
            <li>A statement that the information in the notification is accurate and, under penalty of perjury, you are authorized to act on behalf of the copyright owner</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">3. Counter-Notice</h2>
          <p className="text-gray-400">
            If you believe your content was wrongly removed due to a mistake or misidentification, you can send a counter-notice containing:
          </p>
          <ul className="list-disc pl-6 mt-2 text-gray-400 space-y-1">
            <li>Your physical or electronic signature</li>
            <li>Identification of the removed content and its original location</li>
            <li>A statement under penalty of perjury that you have a good faith belief the content was removed by mistake</li>
            <li>Your name, address, and telephone number</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">4. Repeat Infringers</h2>
          <p className="text-gray-400">
            We have a policy of terminating the accounts of users who repeatedly infringe the intellectual property rights of others.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">5. Contact Information</h2>
          <p className="text-gray-400">
            DMCA notices should be sent to our designated copyright agent at:<br />
            cnbuy.io Ecommerce Shopping Solutions<br />
            <br />
            Email: dmca@cnbuy.io
          </p>
        </section>
      </div>
    </div>
  );
};

export default DMCANotice;
