import React from 'react';
import ProductCard from './ProductCard';
import { useAgent } from './AgentSelector';

const ProductShowcase = () => {
  const { selectedAgent } = useAgent();

  // Sample product data for demonstration
  const sampleProducts = [
    {
      title: "超薄便携汽车应急启动电源Jump starter大容量汽车电 瓶亏电搭电宝",
      price: "250.00",
      imageUrl: "https://ae-pic-a1.aliexpress-media.com/kf/S7dd28663c9ef400eba90a60938315ba1z/Baseus-Auto-Starthilfe-600a-6000mah-Autobatterie-ladeger-t-Not-strom-bank-Booster-Start-ger-t-12V.jpg_.webp",
      productUrl: "",
      sales: "10k+",
      rating: "5",
      rePurchaseRate: "95",
      orderUrl: "test.com"
    },
    {
      title: "超薄便携汽车应急启动电源Jump starter大容量汽车电 瓶亏电搭电宝",
      price: "159.00",
      imageUrl: "https://ae-pic-a1.aliexpress-media.com/kf/S46911a76190b42f7a110498028afc06bD/Neue-Version-Amazfit-GTR-2-neue-Version-Smartwatch-Alexa-integrierte-extrem-lange-Akkulaufzeit-Smartwatch-f.jpg_.webp",
      productUrl: "",
      sales: "5k+",
      rating: "4",
      rePurchaseRate: "92",
      orderUrl: "test.com"
    },
    {
      title: "厂家批发成人声波防水电动牙刷充电款-S709",
      price: "90.00",
      imageUrl: "/img/showcase/ebrushes.jpg",
      productUrl: "",
      sales: "5k+",
      rating: "4",
      rePurchaseRate: "92",
      orderUrl: "test.com"
    },
    {
      title: "K1K9pro无线领夹式麦克风 户外手机直播话筒迷你降噪领夹麦克风",
      price: "90.00",
      imageUrl: "/img/showcase/mic.jpg",
      productUrl: "",
      sales: "5k+",
      rating: "4",
      rePurchaseRate: "92",
      orderUrl: "test.com"
    },
    {
      title: "轻薄款羽绒服男短款2024年冬新款季男士薄款90白鸭绒轻便内胆外套",
      price: "129.00",
      imageUrl: "/img/showcase/jacket.jpg",
      productUrl: "",
      sales: "8k+",
      rating: "5",
      rePurchaseRate: "97",
      orderUrl: "test.com"
    },
    {
      title: "圣诞猫窝冬季保暖四季通用猫屋封闭式加绒加厚猫咪猫房子帐篷屋",
      price: "35.00",
      imageUrl: "/img/showcase/圣诞猫窝冬季保暖四季通用猫屋封闭式加绒加厚猫咪猫房子帐篷屋.jpg",
      productUrl: "",
      sales: "8k+",
      rating: "5",
      rePurchaseRate: "97",
      orderUrl: "test.com"
    },
    {
      title: "纯色气质女士包2024时尚夏季新款石头纹腋下包休闲单肩手拎包.jpg",
      price: "35.00",
      imageUrl: "/img/showcase/纯色气质女士包2024时尚夏季新款石头纹腋下包休闲单肩手拎包.jpg",
      productUrl: "",
      sales: "8k+",
      rating: "5",
      rePurchaseRate: "97",
      orderUrl: "test.com"
    },
    {
      title: "美式休闲基础款纯色圆领卫衣男秋冬ins潮牌加绒宽松简约情侣上衣",
      price: "35.00",
      imageUrl: "/img/showcase/美式休闲基础款纯色圆领卫衣男秋冬ins潮牌加绒宽松简约情侣上衣.jpg",
      productUrl: "",
      sales: "8k+",
      rating: "5",
      rePurchaseRate: "97",
      orderUrl: "test.com"
    }
  ];

  // Create a continuous track of products
  const products = [...sampleProducts, ...sampleProducts, ...sampleProducts, ...sampleProducts];

  return (
    <div className="relative opacity-80 overflow-hidden pt-4 ">
      {/*<h2 className="text-2xl font-bold text-white mb-8 text-center">Popular Products</h2>*/}
      
      <div className="w-screen -mx-4">
        {/* Gradient overlays */}
        <div className="absolute left-0 top-0 bottom-0 w-32 bg-gradient-to-r from-gray-900 to-transparent z-10" />
        <div className="absolute right-0 top-0 bottom-0 w-32 bg-gradient-to-l from-gray-900 to-transparent z-10" />
        
        {/* Sliding track */}
        <div className="inline-flex animate-slide">
          <div className="flex">
            {products.map((product, index) => (
              <div key={index} className="flex-none w-[250px] mx-4">
                <ProductCard {...product} selectedAgent={selectedAgent} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductShowcase;
