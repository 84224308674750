import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import HeroSection from "./components/HeroSection";
import ProductCard from "./components/ProductCard";
import SearchBar from "./components/SearchBar";
import ErrorPopup from "./components/ErrorPopup";
import { fetchImageDetails, fetchProducts } from "./services/api";
import AgentSelector, { useAgent } from "./components/AgentSelector";

function FetchAndDisplayProducts() {
  const location = useLocation();
  const [imageUrl, setImageUrl] = useState("");
  const [productTitle, setProductTitle] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [baseSourceUrl, setBaseSourceUrl] = useState('');
  const { selectedAgent } = useAgent();
  
  const encodedUrl = location.pathname.substring(1);
  const productUrl = decodeURIComponent(encodedUrl);

  useEffect(() => {
    if (productUrl && productUrl !== "") {  
      try {
        const url = new URL(productUrl);
        
        // Check for HTTPS
        if (url.protocol !== 'https:') {
          throw new Error('Only HTTPS URLs are supported for security reasons.');
        }

        // Check for valid hostname
        if (!url.hostname.includes('.') || url.hostname.endsWith('.') || url.hostname.startsWith('.')) {
          throw new Error('Invalid domain name format.');
        }

        // Check for common URL patterns
        const suspiciousPatterns = ['localhost', '127.0.0.1', '.local', 'file:/'];
        if (suspiciousPatterns.some(pattern => url.href.includes(pattern))) {
          throw new Error('Local or invalid URLs are not supported.');
        }

        setBaseSourceUrl(url.hostname);
        loadData(productUrl);  
      } catch (err) {
        setError(err.message || 'Invalid URL provided. Please check the URL and try again.');
        setShowErrorPopup(true);
      }
    }
  }, [productUrl]);

  // Handle data fetching
  const loadData = async (url) => {
    if (!url) return;
    
    setLoading(true);
    try {
      const imageDetails = await fetchImageDetails(url);
      setImageUrl(imageDetails.imageUrl);
      setProductTitle(imageDetails.pageTitle ? imageDetails.pageTitle.split(" - ")[0] : "Product");
      
      const productsData = await fetchProducts(imageDetails.imageUrl);
      setProducts(productsData.products || []);
    } catch (err) {
      console.error("Error caught in FetchAndDisplayProducts:", err);
      console.log("Error status:", err.status);
      console.log("Error type:", typeof err);
      console.log("Error properties:", Object.keys(err));
      
      if (err.status === 403) {
        setError("This URL could not be approved for its contents. If you would like to request approval for this URL, please contact our support team.");
      } else {
        setError(err.message || 'Failed to load data');
      }
      setShowErrorPopup(true);
    } finally {
      setLoading(false);
    }
  };

  // Render the home page if no URL is provided
  if (!productUrl || productUrl === "") {
    return (
      <div className="min-h-screen bg-gray-100">
        <HeroSection />
        <SearchBar />
      </div>
    );
  }

  const Footer = () => {
    return (
      <footer className="bg-gray-900 text-gray-400 py-4 sm:py-8 border-t border-gray-800">
        <div className="container mx-auto px-4 max-w-screen-xl">
          {/*Disclaimer*/}
          <p className="text-center text-gray-400 text-xs sm:text-sm mb-4 sm:mb-6 px-2 sm:px-4">
            Disclaimer: This website is intended to assist users browsing Chinese marketplaces, compare products, and convert currencies. We do not endorse, verify, or guarantee the legitimacy, authenticity, or legality of any products, sellers, or linked websites. Users are advised to proceed at their own discretion. We explicitly prohibit any usage of our site for any unintended purposes. It is the user's responsibility to thoroughly verify products and sellers before engaging in any transactions. The information provided is for reference only, and we assume no liability for any actions taken based on the content of this website. <br/>
            By using this website, you acknowledge and agree to our terms and conditions, privacy policy, and DMCA notice.
            <br/>Cnbuy.io is not affiliated with any of the sites listed on this platform.
          </p>
          <div className="flex flex-wrap justify-center gap-8">
            <Link to="/terms" className="hover:text-blue-500 transition-colors">Terms of Service</Link>
            <Link to="/privacy" className="hover:text-blue-500 transition-colors">Privacy Policy</Link>
            <Link to="/dmca" className="hover:text-blue-500 transition-colors">DMCA Notice</Link>
          </div>
          <div className="text-center mt-4 text-sm">
            &copy; {new Date().getFullYear()} cnbuy.io All rights reserved.
          </div>
        </div>
      </footer>
    );
  };

  return (
    <>
      <div className="min-h-screen bg-black">
        <ErrorPopup
          isOpen={showErrorPopup}
          onClose={() => setShowErrorPopup(false)}
          message={error}
        />
        {/* Fixed header with search bar */}
        <div className="fixed top-0 left-0 right-0 z-20 bg-gray-900 bg-opacity-95 backdrop-blur-sm shadow-lg">
          <div className="max-w-7xl mx-auto px-4 py-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4 w-max">
                <a href="/">
                  <img src="/img/cnbuy.io.png" alt="cnbuy.io" className="hidden md:block h-8 mr-4" />
                </a>
              </div>
              <div className="flex items-center space-x-4">
                <SearchBar />
                <AgentSelector />
              </div>
            </div>
          </div>
        </div>

        {/* Hero section with padding for fixed header */}
        <div className="fixed top-16 left-0 right-0 h-[60vh] z-0 flex items-center justify-center">
          <HeroSection
            imgSrc={imageUrl}
            title={productTitle}
            source={baseSourceUrl}
          />
        </div>
        
        <div className="relative z-10 mt-[calc(60vh+4rem)] rounded-t-[2rem] bg-gray-900 min-h-screen shadow-2xl">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
            {loading ? (
              <div className="flex items-center justify-center h-32">
                <p className="text-gray-400 text-lg">Loading similar products...</p>
              </div>
            ) : error ? (
              <div className="flex items-center justify-center h-32">
                <p className="text-red-400 text-lg">{error}</p>
              </div>
            ) : (
              <>
                <h2 className="text-2xl font-semibold text-white mb-8">Similar Products</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 md:gap-6 lg:gap-8">
                  {products.map((product, index) => (
                    <ProductCard
                      key={index}
                      {...product}
                      selectedAgent={selectedAgent}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default FetchAndDisplayProducts;
