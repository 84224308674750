const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://api.cnbuy.io';

export const fetchImageDetails = async (url) => {
  try {
    const response = await fetch(`${API_BASE_URL}/fetch-image?url=${encodeURIComponent(url)}`);
    if (!response.ok) {
      const data = await response.json();
      const error = new Error(data.message || 'Failed to fetch image details');
      error.status = response.status;
      throw error;
    }
    const data = await response.json();
    return {
      imageUrl: data.imageUrl,
      pageTitle: data.pageTitle
    };
  } catch (error) {
    if (error.status) {
      throw error; 
    }
    const newError = new Error(`Error fetching image details: ${error.message}`);
    newError.status = error.status || 500;
    throw newError;
  }
};

export const fetchProducts = async (imageUrl, page = 1, sort = 'default') => {
  try {
    const queryParams = new URLSearchParams({
      imageUrl: imageUrl,
      page: page.toString(),
      sort: sort
    });

    const response = await fetch(`${API_BASE_URL}/get-products?${queryParams}`);
    if (!response.ok) {
      const data = await response.json();
      const error = new Error(data.message || 'Failed to fetch products');
      error.status = response.status;
      throw error;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    if (error.status) {
      throw error;
    }
    const newError = new Error(`Error fetching products: ${error.message}`);
    newError.status = error.status || 500;
    throw newError;
  }
};

// Available sort options
export const SORT_OPTIONS = {
  DEFAULT: 'default',
  SALES_DESC: 'salesDesc',
  PRICE_ASC: 'priceAsc',
  PRICE_DESC: 'priceDesc'
};
