import React, { useState } from "react";

const ProductCard = ({ title, price, imageUrl, productUrl, sales, rating, rePurchaseRate, selectedAgent }) => {

  // Function to truncate text to a specific length
  const truncateText = (text, maxLength = 50) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const formatPrice = (price) => {
    // Convert from CNY to USD (approximate conversion rate)
    const usdPrice = (parseFloat(price) * 0.14).toFixed(2);
    return usdPrice;
  };

  // Function to render star rating
  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;

    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(<span key={i} className="text-yellow-400">★</span>);
      } else if (i === fullStars && hasHalfStar) {
        stars.push(<span key={i} className="text-yellow-400">⯨</span>);
      } else {
        stars.push(<span key={i} className="text-gray-600">★</span>);
      }
    }
    return stars;
  };

  return (
    <div className="bg-gray-800 rounded-xl overflow-hidden transition-all duration-300 hover:scale-[1.02] hover:shadow-xl flex flex-col h-full">
      {/* Image Container */}
      <div className="aspect-square overflow-hidden relative group">
        <img
          alt={title}
          className="w-full h-full object-cover transition-all duration-500 group-hover:scale-110"
          src={imageUrl}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-50 group-hover:opacity-0 transition-opacity duration-300" />
      </div>

      {/* Content Container */}
      <div className="flex-1 p-4 flex flex-col space-y-3">
        {/* Title */}
        <h3 
          className="text-base font-medium text-white line-clamp-2 cursor-pointer hover:text-blue-400 transition-colors duration-300" 
          title={title}
          onClick={() => window.open(selectedAgent?.orderUrl ? `${selectedAgent.orderUrl}${encodeURIComponent(productUrl)}` : productUrl, "_blank")}
        >
          {title}
        </h3>
        
        {/* Rating and View Details */}
        <div className="flex flex-col space-y-1.5">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className="flex text-sm">{renderStars(parseFloat(rating))}</div>
              <span className="text-xs text-gray-400">({rating})</span>
            </div>
            <a
              href={productUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-white hover:text-blue-400 transition-colors duration-300 flex items-center"
            >
              View Details
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
              </svg>
            </a>
          </div>
        </div>

        {/* Price and Stats */}
        <div className="mt-auto space-y-3">
          <div className="flex items-center justify-between">
            <span className="text-2xl font-bold text-white">
              ${formatPrice(price)}
            </span>
            <div className="flex items-center space-x-3 text-xs text-gray-400">
              <span>Sales: {sales || 0}</span>
              {rePurchaseRate && (
                <>
                  <span>•</span>
                  <span>Repurchase: {rePurchaseRate}%</span>
                </>
              )}
            </div>
          </div>

          {/* Order Button */}
          {selectedAgent && selectedAgent.orderUrl && (
            <a
              href={`${selectedAgent.orderUrl}${encodeURIComponent(productUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full py-2 px-4 bg-white bg-opacity-10 hover:bg-opacity-20 text-white rounded-lg transition-all duration-300 flex items-center justify-center space-x-2"
            >
              <img src={selectedAgent.logo} alt={selectedAgent.name} className="h-4 w-4 object-contain" />
              <span className="text-sm font-medium">Order on {selectedAgent.name || 'Agent'}</span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
