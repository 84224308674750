import React from 'react';

const SupportedSites = () => {
  const sites = [
    {
      name: "1688",
      logo: "https://cdn-1.webcatalog.io/catalog/1688-com/1688-com-icon-filled-256.webp",
    },
    {
      name: "Taobao",
      logo: "https://cdn4.iconfinder.com/data/icons/chinas-social-share-icons/256/cssi_taobao-1024.png",
    },
    {
      name: "Weidian",
      logo: "https://sampi.co/wp-content/uploads/2016/03/Weidian-logo.png",
    },
    {
      name: "Tmall",
      logo: "https://cdn.worldvectorlogo.com/logos/tmall-1.svg",
    },
    /*{
      name: "Pinduoduo",
      logo: "https://s3-symbol-logo.tradingview.com/pinduoduo--600.png",
    }*/
  ];

  return (
    <div className="w-full max-w-2xl mx-auto px-4 ">
      {/*<p className="text-center text-gray-400 text-sm mb-6">Supported Sites</p>*/}
      <div className="flex items-center justify-center gap-8 flex-wrap">
        {sites.map((site, index) => (
          <div 
            key={index} 
            className="flex flex-col items-center group"
          >
            <div className="w-14 h-14 bg-gray-800/50 rounded-xl p-2 backdrop-blur-sm border border-gray-700/30 transition-all duration-300 group-hover:scale-110 group-hover:border-blue-500/30">
              <img 
                src={site.logo} 
                alt={site.name} 
                className="w-full h-full object-contain filter brightness-90 group-hover:brightness-100 rounded-lg"
              />
            </div>
            <span className="mt-2 text-xs text-gray-500 group-hover:text-gray-300 transition-colors duration-300">
              {site.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SupportedSites;
